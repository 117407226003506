<template>
    <div class="card" v-if="user.nombre">
      <div class="card-body">
        <h4 class="">{{user.nombre}} {{user.apellido}}</h4>
        <hr>
        <div class="row">
          <div class="col-12 mb-2">
            <h5><b>Tipo: <span :class="tipoClass">{{user.tipo}}</span></b></h5>
          </div>
          <div class="col-auto">
            <span class="bold">ID:</span> <span class="text-info">{{user.id}}</span>
          </div>
          <div class="col-auto">
            <span class="bold">CUIL:</span> <span class="text-alternate">{{user.cuil | cuil}}</span>
          </div>
          <div class="col-auto">
            <span class="bold">Email:</span> <span class="text-alternate">{{user.email}}</span>
          </div>
          <div class="col-auto">
            <span class="bold">Whatsapp:</span> <span class="text-success">{{user.whatsapp}}</span>
          </div>
          <div class="col-auto">
            <span class="bold">Creado:</span> <span class="text-primary">{{user.created_at | formatDate()}}</span>
          </div>
        </div>
        <hr>
        <div class="d-flex justify-content-between mt-2 flex-column flex-md-row">
          <button class="btn btn-primary my-1" v-if="user.tipo != 'admin'" @click="toAdmin" >Convertir a Administrador</button>
          <button class="btn btn-info my-1" v-if="user.tipo != 'user'"  @click="toUser">Convertir a Usuario</button>
          <button class="btn btn-success my-1" v-if="user.tipo != 'dev'" @click="toDev">Convertir a Dev</button>
          <router-link :to="'/usuarios/editar/'+user.id" class="btn btn-alternate my-1" >Editar usuario</router-link>
          <button @click="eliminarUser" class="btn btn-danger my-1" >Eliminar usuario</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <TablaRecibos class="m-2" :recibos="user.recibos" @actualizar="getUser"/>
        </div>
      </div>
    </div>
</template>
<script>
import User from "@/models/user";
import TablaRecibos from "@/components/TablaRecibos";
import Axios from 'axios'
import Swal from 'sweetalert2'
export default {
  name: "VerUsuario",
  data() {
    return {
      user: new User(null, null, null, null, null, null, null),
    };
  },
  components: {
    TablaRecibos,
  },
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
  created() {
    this.user.id = this.$route.params.id;
    this.getUser();
  },
  computed:{
    tipoClass: function(){
      if(this.user.tipo == 'dev'){
        return 'text-success'
      }else if(this.user.tipo == 'admin'){
        return 'text-primary'
      }else{
        return 'text-secondary'
      }
    }
  },
  methods: {
    getUser() {
      Axios.get("user/" + this.user.id)
        .then((res) => {
          if (res.data.success) {
            this.user = res.data.user;
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "warning",
            title: "Oopss..",
            text: `No se ha encontrado user.`,
          });
        });
    },
    eliminarUser() {
      Swal.fire({
        title: "¿Estás seguro?",
        text: `¿Deseas eliminar a ${this.user.nombre} ${this.user.apellido} de manera permanente?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("user/" + this.user.id)
            .then(() => {
              Swal.fire(
                "Eliminado",
                `${this.user.nombre} ${this.user.apellido} se ha eliminado con éxito.`,
                "success"
              );
              this.$router.push('ListarUsuarios')
            })
            .catch((err) => {
              console.log(err)
              Swal.fire(
                "Error",
                `${err}`,
                "Error"
              );  
            })
        }
      });
    },
    toAdmin(){
      Axios.post("/user/to-admin/" + this.user.id)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: `${res.data.user.nombre} se guardó con éxito.`,
            });
            this.getUser();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `No se pudo editar`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${this.user.nombre} no se guardó.
            ${err}`,
          });
          console.log(err);
        });
    },
    toUser(){
      Axios.post("/user/to-user/" + this.user.id)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: `${res.data.user.nombre} se guardó con éxito.`,
            });
            this.getUser();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `No se pudo editar`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${this.user.nombre} no se guardó.
            ${err}`,
          });
          console.log(err);
        });
    },
    toDev(){
      Axios.post("/user/to-dev/" + this.user.id)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: "Éxito",
              text: `${res.data.user.nombre} se guardó con éxito.`,
            });
            this.getUser();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `No se pudo editar`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${this.user.nombre} no se guardó.
            ${err}`,
          });
          console.log(err);
        });
    },
  },
};
</script>